import {navbarToggler} from "./modules/_navbar.js";
import {SmoothScroll} from "./modules/_smooth-scroll";
import {gsapAnimations} from "./modules/_animation";
import {sideBtnAnimation} from "./modules/_helpers";

require('fslightbox');

document.addEventListener("DOMContentLoaded", function () {
  navbarToggler().init()
  SmoothScroll()
  gsapAnimations.animateHeader()
  gsapAnimations.sideBtnLTR()
  sideBtnAnimation()

  if (window.innerWidth > 1600) {
    gsapAnimations.init()
  }
})
