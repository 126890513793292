/* is Mobile Device
**************************************************************/
function isMobDevice() {
  let w =
    window.innerWidth ||
    document.documentElement.clientWidth ||
    document.body.clientWidth;

  return Boolean(w < 1366);
}

/* is Tablet Device
**************************************************************/
const userAgent = navigator.userAgent.toLowerCase();
const isTablet = /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(userAgent);
const isIpad = /Macintosh/i.test(userAgent) && navigator.maxTouchPoints && navigator.maxTouchPoints > 1;

const slideToggle = (el) => {
  const isShown = el.classList.contains('shown')
  let elHeight = 0

  if (isShown) {
    Array.prototype.forEach.call(el.children, child => elHeight += child.clientHeight)
    el.style.height = `${elHeight}px`
  } else {
    el.style.height = ''
  }
}

const delay = (n) => {
  n = n || 2000

  return new Promise((done) => {
    setTimeout(() => {
      done()
    }, n)
  })
}

/* Get siblings
**************************************************************/
let getSiblings = n => [...n.parentElement.children].filter(c => c.nodeType == 1 && c != n);

const getURLparams = () => {
  let url = new URL(document.URL)
  let params = url.searchParams

  let data = {}
  const entries = params.keys()

  for (const entry of entries) {
    let value = params.get(entry)
    data[entry] = value
  }

  return data
}

const updateURLparams = (data) => {
  let url = new URL(document.URL)
  let params = url.searchParams

  for (const [key, value] of Object.entries(data)) {
    params.set(key, value)
    if (!value || value === 'all') params.delete(key)
  }

  url.search = params.toString()
  window.history.pushState('', '', url)
}

const handleFilter = (e) => {
  const filter = e.currentTarget

  let data = getURLparams()
  data[filter.dataset.type] = filter.value || filter.dataset.value

  updateURLparams(data)
}

function sideBtnAnimation() {
  //side-buttons show animation
  const sideButtons = document.querySelectorAll('.side-featured-buttons');
  if (sideButtons.length) {
    sideButtons.forEach(el => {
      const elWrap = el.querySelector('.side-featured-buttons__wrap');
      const elIcon = el.querySelector('.side-featured-buttons__icon');

      if (window.innerWidth < 1024) {
        elWrap.classList.add('active')
      }

      elIcon.addEventListener('click', () => {
        elWrap.classList.toggle('active');
      })
    });
  }
}

export {
  isMobDevice,
  isTablet,
  isIpad,
  slideToggle,
  delay,
  getSiblings,
  updateURLparams,
  getURLparams,
  handleFilter,
  sideBtnAnimation,
}
