import {gsap} from "gsap";
import {ScrollToPlugin} from "gsap/ScrollToPlugin";
import {ScrollTrigger} from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

const gsapAnimations = {
  refresh: function () {
    ScrollTrigger.refresh()
  },

  anchorLinks: function () {
    gsap.utils.toArray('a[href^="#"]').forEach(function (a) {
      a.addEventListener("click", function (e) {
        e.preventDefault();
        const id = e.target.getAttribute("href"),
          trigger = ScrollTrigger.getById(id);
        gsap.to(window, {
          duration: 1,
          scrollTo: trigger ? trigger.end : id,
        });
      });
    });
  },

  pinSection: function () {
    gsap.utils.toArray(".js-pin-section").forEach((panel) => {
      ScrollTrigger.create({
        trigger: panel,
        start: "top top",
        pin: true,
        pinSpacing: false,
      });
    });
  },

  fadeInClass: function () {
    gsap.utils.toArray(".js-fade-in-class").forEach((section) => {
      gsap.to(section, {
        ease: "none",
        scrollTrigger: {
          trigger: section,
          start: "top 80%",
          end: "+=150%",
          toggleClass: 'css-fade-in',
          scrub: true,
        },
      });
    });
  },

  fadeIn: function () {
    gsap.utils.toArray(".js-fade-in").forEach((section) => {
      gsap.fromTo(section,
        {
          opacity: 0,
        },
        {
          opacity: 1,
          ease: 'power1.inOut',
          scrollTrigger: {
            trigger: section,
            start: '-800px top',
            end: `+=300px`,
            scrub: true,
          },
        })
    });
  },

  fadeInReviews: function () {
    gsap.utils.toArray(".js-fade-in-reviews").forEach((section) => {
      gsap.fromTo(section,
        {
          opacity: 0,
        },
        {
          opacity: 1,
          ease: 'power1.inOut',
          scrollTrigger: {
            trigger: section,
            start: '-800px top',
            end: `+=600px`,
            scrub: true,
          },
        })
    });
  },

  fadeInLeft: function () {
    gsap.utils.toArray(".js-fade-in-left").forEach((section) => {
      gsap.fromTo(section,
        {
          opacity: 0,
          left: "-100px",
        },
        {
          opacity: 1,
          left: "0",
          ease: 'power1.inOut',
          scrollTrigger: {
            trigger: section,
            start: '-800px top',
            end: `+=300px`,
            scrub: true,
          },
        })
    });
  },

  fadeInTop: function () {
    gsap.utils.toArray(".js-fade-in-top").forEach((section) => {
      gsap.to(section, {
        ease: "none",
        scrollTrigger: {
          trigger: section,
          start: "top 80%",
          end: "+=150%",
          toggleClass: 'css-fade-in-top',
          scrub: true,
        },
      });
    });
  },

  fadeInLeftAddClass: function () {
    gsap.utils.toArray(".js-fade-in-left-class").forEach((section) => {
      gsap.to(section, {
        ease: "none",
        scrollTrigger: {
          trigger: section,
          start: "top 80%",
          end: "+=150%",
          toggleClass: 'css-fade-in-left',
          scrub: true,
        },
      });
    });
  },

  fadeInRightAddClass: function () {
    gsap.utils.toArray(".js-fade-in-right-class").forEach((section) => {
      gsap.to(section, {
        ease: "none",
        scrollTrigger: {
          trigger: section,
          start: "top 80%",
          end: "+=150%",
          toggleClass: 'css-fade-in-right',
          scrub: true,
        },
      });
    });
  },

  fadeInLeftDelay: function () {
    gsap.utils.toArray(".js-fade-in-left-delay").forEach((section) => {
      gsap.fromTo(section,
        {
          opacity: 0,
          left: "-100px",
        },
        {
          opacity: 1,
          left: "0",
          ease: 'power1.inOut',
          scrollTrigger: {
            trigger: section,
            start: '-500px top',
            end: `+=40%`,
            scrub: true,
          },
        })
    });
  },

  fadeInRight: function () {
    gsap.utils.toArray(".js-fade-in-right").forEach((section) => {
      gsap.fromTo(section,
        {
          opacity: 0,
          right: "-100px",
        },
        {
          opacity: 1,
          right: "0",
          ease: 'power1.inOut',
          scrollTrigger: {
            trigger: section,
            start: '-800px top',
            end: `+=300px`,
            scrub: true,
          },
        })
    });
  },

  rightToLeft: function () {
    gsap.utils.toArray(".js-right-to-left").forEach((section) => {
      gsap.fromTo(section,
        {
          right: "-150px",
        },
        {
          opacity: 1,
          right: "0",
          ease: 'power1.inOut',
          scrollTrigger: {
            trigger: section,
            start: '-150% top',
            end: `+=120%`,
            scrub: true,
          },
        })
    });
  },

  leftToRight: function () {
    gsap.utils.toArray(".js-left-to-right").forEach((section) => {
      gsap.fromTo(section,
        {
          left: "-250px",
        },
        {
          opacity: 1,
          left: "0",
          ease: 'power1.inOut',
          scrollTrigger: {
            trigger: section,
            start: '-150% top',
            end: `+=120%`,
            scrub: true,
          },
        })
    });
  },

  sideBtnLTR: function () {
    let mm = gsap.matchMedia();

    mm.add({
      isDesktop: `(min-width: 1600px)`,
      isTablet: `(min-width: 1024px)`,
      isMobile: `(max-width: 1024px)`,
    }, (context) => {
      let { isDesktop, isTablet, isMobile } = context.conditions;

      gsap.utils.toArray(".js-side-btn-ltr").forEach((section) => {

        if (isMobile) {
          console.log('add class');
          section.querySelector('.side-featured-buttons__wrap').classList.add("active");
        }

        gsap.fromTo(section,
          {
            left: isDesktop ? -150 : isTablet ? -80 : isMobile ? -70 : '',
          },
          {
            opacity: 1,
            left: "0",
            ease: 'power1.inOut',
            scrollTrigger: {
              trigger: section,
              start: '-150% top',
              end: `+=120%`,
              scrub: true,
            },
          })
      });
    });
  },

  fadeInRightDelay: function () {
    gsap.utils.toArray(".js-fade-in-right-delay").forEach((section) => {
      gsap.fromTo(section,
        {
          opacity: 0,
          right: "-100px",
        },
        {
          opacity: 1,
          right: "0",
          ease: 'power1.inOut',
          scrollTrigger: {
            trigger: section,
            start: '-500px top',
            end: `+=40%`,
            scrub: true,
          },
        })
    });
  },

  fadeInBottom: function () {
    gsap.utils.toArray(".js-fade-in-bottom").forEach((section) => {
      gsap.fromTo(section,
        {
          opacity: 0,
          bottom: "-100px",
        },
        {
          opacity: 1,
          bottom: "0",
          ease: 'power1.inOut',
          scrollTrigger: {
            trigger: section,
            start: '-200% top',
            end: `+=30%`,
            scrub: true,
          },
        })
    });
  },
  parallaxScroll: function () {
    gsap.utils.toArray(".js-parallax").forEach((section) => {
      section.style.backgroundPosition = `0% ${-innerHeight / 4}px`;

      gsap.to(section, {
        backgroundPosition: `0% ${innerHeight / 4}px`,
        ease: "none",
        scrollTrigger: {
          trigger: section,
          scrub: true,
        },
      });
    });
  },

  parallaxScrollPin: function () {
    gsap.utils.toArray(".js-parallax-pin").forEach((section) => {
      section.style.backgroundPosition = `0% -${innerHeight / 10}px`;

      gsap.to(section, {
        backgroundPosition: `0% ${innerHeight / 8}px`,
        ease: "none",
        scrollTrigger: {
          trigger: section,
          scrub: true,
        },
      });
    });
  },

  parallaxElementOnScroll: function () {
    gsap.utils.toArray(".js-parallax-el").forEach((section) => {
      gsap.fromTo(section,
        {
          top: "0",
        },
        {
          top: "500px",
          ease: 'power1.inOut',
          scrollTrigger: {
            trigger: section,
            start: '-300px top',
            end: `+=1600px`,
            scrub: true,
          },
        })
    });
  },

  animateHeader: function () {
    const scroll_bg = document.querySelector(".header-inner__bg");
    const logo_bg = document.querySelector(".logo-center");
    let dataStart = scroll_bg !== null && scroll_bg.offsetHeight;
    gsap.set(scroll_bg, {opacity: 0});
    gsap.set(scroll_bg, {boxShadow: "0px 0px 0px 0px #00000040 , 0px 0px 0px 0px rgba(0, 0, 0, 0.25)"});

    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: "body",
        scrub: true,
        start: dataStart,
        end: "+=10%",
      },
    });

    tl.to(scroll_bg, {opacity: 1}, "-=1")
    tl.to(logo_bg, {opacity: 1}, "-=1")
    tl.to(logo_bg, {pointerEvents: 'all'})
    tl.to(scroll_bg, {boxShadow: "0px 0px 15px 0px #00000040 , 0px 0px 15px 0px rgba(0, 0, 0, 0.25)"})
  },

  init: function () {
    gsapAnimations.parallaxScroll()
    gsapAnimations.parallaxScrollPin()
    gsapAnimations.parallaxElementOnScroll()
    gsapAnimations.fadeInClass()
    gsapAnimations.fadeIn()
    gsapAnimations.fadeInReviews()
    gsapAnimations.fadeInTop()
    gsapAnimations.fadeInLeft()
    gsapAnimations.fadeInLeftDelay()
    gsapAnimations.leftToRight()
    gsapAnimations.fadeInRight()
    gsapAnimations.rightToLeft()
    gsapAnimations.fadeInRightDelay()
    gsapAnimations.fadeInBottom()
    gsapAnimations.fadeInLeftAddClass()
    gsapAnimations.fadeInRightAddClass()
    gsapAnimations.pinSection()
    gsapAnimations.anchorLinks()
  },
};

export {
  gsapAnimations,
}
