function SmoothScroll() {
  const links = document.querySelectorAll(`a[href^="#"]`);

  for (const link of links) {
    const href = link.getAttribute('href')

    if( href !== '#' && href !== '#0') {
      link.addEventListener("click", function(e) {
        e.preventDefault();
        const offsetTop = document.querySelector(href).offsetTop;
        const headerHeight = document.querySelector('.header-inner').getBoundingClientRect().height

        scroll({
          top: offsetTop - (headerHeight),
          behavior: "smooth",
        });
      });
    }
  }
}

// function adjustAnchor() {
//   const target = document.querySelector(location.hash);
//   const header = document.querySelector('.header');

//   if(target) {
//     const offsetTop = target.offsetTop;
//     const headerHeight = header.getBoundingClientRect().height

//     scroll({
//       top: offsetTop - (headerHeight),
//       behavior: "smooth",
//     });
//   }
// }

export {
  SmoothScroll,
  // adjustAnchor,
}
