import { slideToggle, isMobDevice } from "../modules/_helpers"

const navbarToggler = () => {
  const body = document.querySelector('body');
  const btnMenu = document.querySelector('.navbar-toggler');
  const btnClose = document.querySelector('.header-inner__navbar_close');
  const siteNav = document.querySelector('.header-inner__navbar');
  const overlay = document.querySelector('.header-inner__overlay');
  let lastScrollTop = 0;
  let isOpen = false;

  function hideMenu() {
    body.classList.remove('fixed');
    btnMenu.classList.remove('opened');
    siteNav.classList.remove('opened');
    overlay.classList.remove('opened');
    lastScrollTop = body.style.top.replace('px', '').replace('-', '');
    body.style.removeProperty('margin-left');
    body.style.removeProperty('overflow');
    body.style.removeProperty('position');
    body.style.removeProperty('top');
    body.style.removeProperty('width');
    window.scrollTo(0, lastScrollTop);

    closeDropdownMenu()
    isOpen = false;
  }

  function showMenu() {
    body.classList.add('fixed');
    btnMenu.classList.add('opened');
    siteNav.classList.add('opened');
    overlay.classList.add('opened');
    lastScrollTop = window.pageYOffset;
    body.style.top = `-${lastScrollTop}px`;
    isOpen = true;
  }

  function ctaMenu() {
    let cta = document.querySelectorAll('.js-header-link');
    if(cta.length > 0) {
      for(let i = 0; i < cta.length; i++) {
        cta[i].addEventListener('click', function() {
          hideMenu()
        })
      }
    }
  }

  /* Close Dropdown menu, after click btnClose
  **************************************************************/
  function closeDropdownMenu(container = document) {
    const dropdownOpened = container.querySelectorAll('.dropdown-toggle.opened');
    const dropdownMenuOpened = container.querySelectorAll('.dropdown-menu.shown');
    const caretOpened = container.querySelectorAll('.caret.opened');

    if(!dropdownOpened) return
    dropdownOpened.forEach(dropdown => dropdown.classList.remove('opened') );
    dropdownMenuOpened.forEach(dropdownMenu => {
      dropdownMenu.classList.remove('shown')
      slideToggle(dropdownMenu);
    } );
    caretOpened.forEach(caret => caret.classList.remove('opened') );
  }

  /* Funtion open/close dropdown menu
  **************************************************************/
  function toggleDropdownMenu() {
    let caret = document.querySelectorAll('.nav-primary ul > li.menu-item-has-children .caret');

    // Caret click
    if(caret.length > 0) {
      for(let i = 0; i < caret.length; i++) {
        caret[i].addEventListener('click', function(e) {
          isMobDevice() ? handleDropdown(e, caret, i) : ''
        })
      }
    }

    function handleDropdown(e, opener, i) {
      setDataHeight()
      e.preventDefault();
      let menuItems = opener[i];
      let dropdownMenu = menuItems.parentElement.querySelector('.dropdown-menu');
      let dropdownMenuParent = menuItems.parentElement.parentElement.parentElement.querySelector('.dropdown-menu');
      let caret = [...menuItems.parentElement.querySelectorAll('.caret')].pop();

      if (dropdownMenu.classList.contains('shown')) {
        dropdownMenu.classList.remove('shown');
        menuItems.classList.remove('opened');
        caret.classList.remove('opened');
        slideToggle(dropdownMenu);

        // height dropdownMenuParent
        if( dropdownMenu.getAttribute('data-level') == 1 ) {
          updateHeightParentDropdown(dropdownMenuParent)
        }

        // Close child dropdowns
        closeDropdownMenu(dropdownMenu)
      } else {
        opener.forEach(element => {
          if(element !== opener[i]) {
            opener[i].classList.remove('active');
            element.classList.remove('active')
          }
        })

        dropdownMenu.classList.add('shown');
        menuItems.classList.add('opened');
        caret.classList.add('opened');
        slideToggle(dropdownMenu);

        // height dropdownMenuParent
        if( dropdownMenu.getAttribute('data-level') == 1 ) {
          updateHeightParentDropdown(dropdownMenuParent)
        }
      }
    }
  }

  function setDataHeight() {
    let items = document.querySelectorAll('.nav-primary__mobile [data-level]');
    items.forEach(item => {
      if( !item.hasAttribute('data-height') ) {
        let elHeight = 0
        Array.prototype.forEach.call(item.children, child => elHeight += child.clientHeight)
        elHeight = parseInt(elHeight, 10)
        item.setAttribute('data-height', elHeight)
      }
    })
  }

  function updateHeightParentDropdown(dropdownMenuParent) {
    let heightTotal = parseInt(dropdownMenuParent.getAttribute('data-height'), 10)
    let dropdowns = dropdownMenuParent.querySelectorAll('.dropdown-menu.shown')
    dropdowns.forEach(dropdown => heightTotal += parseInt(dropdown.getAttribute('data-height'), 10))
    dropdownMenuParent.style.height = heightTotal + "px";
  }

  function handleMenuButtonClick() {
    siteNav.classList.remove('opened');

    if (isOpen) {
      hideMenu();
    } else {
      showMenu()
    }
  }

  return {
    handleEvent(event) {
      if (event.type === 'click') {
        handleMenuButtonClick();
      }
    },

    init() {
      ctaMenu()
      toggleDropdownMenu();
      btnMenu.addEventListener('click', this);
      btnClose.addEventListener('click', this);
    },
  }
}

/*
**************************************************************/

export {
  navbarToggler,
}
